<template>
  <nav role="navigation" aria-labelledby="skip-nav__title" class="skip-nav">
    <h2 id="skip-nav__title" class="skip-nav__heading">
      Springen bis zu
    </h2>
    <a href="#main-content" class="skip-nav__link">
      Hauptinhalt
    </a>
    <a href="#main-nav" class="skip-nav__link" @click="openNav()">
      Hauptnavigation
    </a>
    <a href="#player-header" class="skip-nav__link">
      Radio player
    </a>
    <a href="#player-meta__track-info" class="skip-nav__link">
      Aktueller Beitrag
    </a>
  </nav>
</template>

<script setup lang="ts">

const openHeader = useState('openHeader', () => false)

function openNav () {
  openHeader.value = true
  const navigationContent = document.querySelector('#main-nav')
  useProgrammaticFocus(navigationContent as HTMLElement)
}

</script>

<style lang="scss">
@use "~/assets/sass/tools";

.skip-nav {
  position: fixed;
  display: flex;
  top: var(--grid-gutter);
  left: var(--grid-gutter);
  flex-direction: column;
  padding: var(--space--s) 0;
  background-color: var(--color-background);
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index--header);
  @include tools.boxshadow-layout;

  &:focus-within,
  &:focus {
    opacity: 1;
    pointer-events: all;
  }

  &__heading {
    margin: 0;
    padding: var(--space--s) var(--space--m);
    @include tools.typo-heading-small;
  }

  &__link {
    padding: var(--space--s) var(--space--m);
  }
}
</style>
