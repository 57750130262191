<template>
  <nav id="main-nav" ref="nav" aria-label="Hauptnavigation" class="nav" role="navigation">
    <ul class="nav__list">
      <li
        v-for="(navItem, index) in appConfig.mainNav"
        :key="`mainNavItem--${index}`"
        class="nav__item"
      >
        <div v-if="!navItem.link" class="nav__item-label">
          {{ navItem.label }}
        </div>
        <NuxtLink v-if="navItem.link" :to="navItem.link" class="nav__item-label nav__item-label--direct" @click="closeHeader()">
          {{ navItem.label }}
        </NuxtLink>
        <ul v-if="navItem.children" class="nav__sub-list">
          <li v-for="(subNavItem, subIndex) in navItem.children" :key="`mainSubNavIten--${subIndex}`" class="nav__sub-item">
            <NuxtLink :to="subNavItem.link" @click="closeHeader()">
              {{ subNavItem.label }}
            </NuxtLink>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const openHeader = useState('openHeader', () => false)

const nav: Ref<HTMLElement | null> = ref(null)

defineEmits(['clickNavLink'])

watch(openHeader, () => {
  if (nav?.value?.scrollTop !== null) {
    return
  }
  nav.value.scrollTop = 0
})

function closeHeader () {
  openHeader.value = false
}

</script>

<style lang="scss">
@use "~/assets/sass/tools";

  .nav {
    @include tools.to(xl) {
      display: flex;
      flex-direction: column;
      overflow: hidden scroll;
    }

    &__list{
      display: flex;
      gap: calc(var(--grid-gutter) / 2);
      margin: 0;
      padding-left: 0;
      @include tools.to(xl) {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(var(--grid-gutter));
        padding: var(--grid-gutter) 0;
        padding-top: calc(var(--header-controller-height) + var(--grid-gutter) * 2);
      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      margin-top: 0;
      padding-left: 0;
      list-style: none;
      overflow: hidden;
      cursor: pointer;

      &:focus-within {
        @include tools.focus-outline;
      }

      a {
        &:hover {
          opacity: 1;
        }
      }
    }

    &__item-label {
      @include tools.nav-item;
      text-decoration: none;
    }

    &__sub-list {
      position: absolute;
      display: flex;
      top: 100%;
      flex-direction: column;
      align-items: flex-start;
      height: 0;
      padding-left: 0;
      overflow: hidden;
      @include tools.to(xl) {
        position: static;
        height: auto;
        margin-top: var(--space--xs);
        padding-left: calc(var(--grid-gutter) / 2);
        overflow: visible;
      }
    }

    &__sub-item {
      @include tools.sub-nav-item;
      z-index: 100;
      margin-top: 0;
      list-style: none;
      white-space: nowrap;
      overflow: hidden;
      @include tools.from(xl) {
        @include tools.boxshadow-nav-item;
      }

      &:focus-within {
        @include tools.focus-outline;
        z-index: var(--z-index--sub-nav-item-focus);

        a {
          outline: 0;
        }
      }
    }

    &__item:hover,
    &__item:focus-within {
      overflow: unset;

      .nav {
        &__sub-list {
          height: auto;
          overflow: visible;
          @include tools.to(xl) {
            margin-top: var(--space--xs);
          }
        }
      }
    }

    &__item:focus-within {
      @include tools.focus-outline;
    }
  }
</style>
