<template>
  <div class="stream" @click="handleClick">
    <audio
      id="stream"
      ref="stream"
      class="stream__audio"
      :src="audioSource"
      preload="none"
      crossorigin="anonymous"
    />
  </div>
</template>

<script setup lang="ts">
import { type EpisodeCurrentProgrammeFragment } from '#graphql-operations'

const config = useRuntimeConfig()

const stream: Ref<HTMLAudioElement | null> = ref(null)
const audioSource = 'https://lora.ice.infomaniak.ch/lora.mp3'

function handleClick (event: Event) {
  stream.value.play()
}

const player = usePlayerState()

onMounted(() => {
  const { currentEpisode } = useCurrentProgramme()
  watch(
    currentEpisode,
    () => {
      setMediaSessionMetadata(currentEpisode)
    }
  )
  watch(
    () => player.value.playing,
    (playing) => {
      if (stream.value === null) { return }
      if (playing) {
        stream.value.play()
        setMediaSessionMetadata(currentEpisode)
      } else {
        stream.value.pause()
      }
    }
  )
})

interface Artwork {
  src: string,
  type?: string,
  sizes?: string
}

function setMediaSessionMetadata (currentEpisode: Ref<EpisodeCurrentProgrammeFragment | undefined>) {
  if (currentEpisode === undefined) {
    return
  }
  let title: string | undefined = ''
  if (currentEpisode.value?.title !== null) {
    title = currentEpisode.value?.title
  }
  let artwork: Artwork[] = [{
    src: `${config.public.appUrl}/images/show-placeholder.png`
  }]
  if (currentEpisode.value?.image?.image?.entity?.uri) {
    const imagePath = useImageUrl(currentEpisode.value?.image?.image?.entity?.uri, 'default_m')
    artwork = [
      {
        src: `${imagePath}.webp`,
        type: 'image/webp'
      },
      {
        src: imagePath
      }
    ]
  }
  navigator.mediaSession.metadata = new MediaMetadata({
    title,
    artist: 'Radio LoRa',
    artwork
  })
}
</script>

<style lang="scss">
@use "~/assets/sass/tools";

.stream {
  &__audio {
    display: none
  }
}
</style>
