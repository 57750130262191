import { formatISO, startOfDay, addDays, subDays } from 'date-fns'
import { useState } from '#app'
import {
  type CurrentProgrammeQuery,
  type EpisodeCurrentProgrammeFragment
} from '#graphql-operations'

export default async function fetchCurrentProgramme (): void {
  const today = Date.now()

  // set min and max date range to filter to (3 days range)
  const dateRangeStart: string = formatISO(startOfDay(subDays(today, 1)))
  const dateRangeEnd: string = formatISO(startOfDay(addDays(today, 2)))

  const data = await useGraphqlQuery('currentProgramme', {
    limit: 1000,
    dateRangeStart,
    dateRangeEnd
  }).then((v) => {
    const currentProgramme: Ref<EpisodeCurrentProgrammeFragment[]> = useState('currentProgramme', () => [])
    const result: CurrentProgrammeQuery = v.data
    currentProgramme.value = result.episodes.items
  })

  setTimeout(() => {
    fetchCurrentProgramme()
  }, 3600000)
}
