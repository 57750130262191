<template>
  <header class="header" :class="{'header--open': openHeader}">
    <div class="header__inner">
      <SkipNav />
      <NuxtLink to="/" class="header__branding" aria-label="Startseite" @click="closeHeader">
        <div class="header__logo">
          <icon name="Logo" fill />
        </div>
        <div class="header__claim" v-html="useSanitizeHtml(appConfig.claim)" />
      </NuxtLink>
      <div class="header__player">
        <ClientOnly>
          <PlayerHeader />
        </ClientOnly>
        <Stream />
      </div>
      <div class="header__mobile-toggle">
        <MenuToggleButton :open="openHeader" />
      </div>
      <div class="header__nav">
        <Nav />
      </div>
      <div class="header__control">
        <NuxtLink to="/suche" class="header__control-item header__control-item--search">
          <icon name="SearchOutline" />
          <div class="header__control-item-label">
            Suche
          </div>
        </NuxtLink>
        <NuxtLink to="/sprachen" class="header__control-item header__control-item--lang">
          <icon name="LanguageOutline" />
          <div class="header__control-item-label">
            Sprache
          </div>
        </NuxtLink>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const openHeader = useState('openHeader', () => false)

const appConfig = useAppConfig()

function closeHeader () {
  openHeader.value = false
}

</script>

<style lang="scss">
@use "~/assets/sass/tools";

  .header {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    height: var(--header-height);
    background-color: var(--color-background);
    z-index: var(--z-index--header);
    @include tools.from(xl) {
      @include tools.boxshadow-layout;
    }

    &::after {
      @include tools.to(xl) {
        @include tools.boxshadow-layout;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: var(--header-height);
        pointer-events: none;
      }
    }

    &__inner {
      display: grid;
      grid-template-columns: max-content repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      height: 100%;
      gap: var(--grid-gutter);
      padding: 0 var(--grid-gutter);
      row-gap: 0;
      @include tools.to(xl) {
        height: auto;
        gap: var(--grid-gutter--s) var(--grid-gutter--xs);
        grid-template-columns: max-content 1fr auto;
      }
    }

    &__branding {
      display: flex;
      align-items: center;
      gap: calc(var(--grid-gutter) / 2);
      grid-row: span 2;
      text-decoration: none;
      @include tools.to(xl) {
        grid-row: span 1;
        height: var(--header-height);
      }

      &:hover {
        opacity: 1;
      }
    }

    &__logo {
      padding: 12px 0 17px;

      .nuxt-icon.icon {
        svg,
        g,
        path {
          margin-top: var(--logo-offset--vertical);
          @include tools.to(xl) {
            width: 48px;
            height: 60px;
          }
        }
      }
    }

    &__claim {
      @include tools.typo-claim-header;
      margin-top: -3px;
      color: var(--color-background);
      -webkit-text-stroke: var(--border-width--s) var(--color-text);
      @include tools.to(xl) {
        display: none;
      }
    }

    &__player {
      display: flex;
      align-items: center;
    }

    &__nav {
      grid-column: 2;
      display: flex;
      align-items: flex-end;
      @include tools.to(xl) {
        position: absolute;
        display: none;
        top: var(--header-height);
        right: 0;
        left: 0;
        flex-direction: column;
        align-items: stretch;
        height: 0;
        padding: 0 calc(var(--space--m) / 2);
        grid-column: 1 / span 3;
        grid-row: 3 / span 1;
      }
    }

    &__mobile-toggle {
      display: none;
      justify-content: flex-end;
      align-items: center;
      @include tools.to(xl) {
        display: flex;
        grid-column: 3;
        grid-row: 1 / span 1;
        height: var(--header-height);
      }
    }

    &__control {
      display: flex;
      justify-content: flex-end;
      gap: var(--grid-gutter--xs);
      grid-row: 1 /span 2;
      grid-column: 3;
      @include tools.to(xl) {
        position: absolute;
        display: none;
        top: var(--header-height);
        right: 0;
        left: 0;
        height: 0;
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
        overflow: hidden;
        z-index: var(--z-index--header);
      }
    }

    &__control-item {
      display: flex;
      align-items: center;
      background-color: var(--color-background);
      border-radius: 50%;

      &:focus-visible {
        outline: 0;

        .icon {
          @include tools.focus-outline;
          border-radius: 50%;
        }
      }
    }

    &__control-item-label {
      position: absolute;
      top: auto;
      left: -10000px;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    &--open {
      @include tools.to(xl) {
        height: 100vh;

        .header {
          &__nav {
            display: flex;
            height: calc(100vh - var(--header-height));
          }

          &__control {
            display: flex;
            height: auto;
            padding: var(--grid-gutter) var(--grid-gutter) 0;
          }
        }
      }
    }
  }
</style>
