<template>
  <div class="player-header">
    <div v-if="currentEpisode" class="player-header__episode">
      <button id="player-header" class="player-header__toggle" :aria-label="`Radio ${playerState.playing ? `pausieren` : `abspielen`}`" @click="togglePlayer">
        <div class="player-header__cover">
          <ResponsiveImage v-if="episodeImage" :image="episodeImage" class="player-header__image" />
          <PlaceholderImage v-else class="player-header__image" />
        </div>
        <PlayerIcon class="player-header__toggle-icon" />
      </button>
      <NuxtLink id="player-header__track-info" class="player-header__info" :to="currentEpisode.path.alias">
        <div class="player-header__title">
          {{ currentEpisode.title }}
        </div>
        <div class="player-header__duration">
          {{ duration }}
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const { currentEpisode } = useCurrentProgramme()
const playerState = usePlayerState()
const { togglePlayer } = usePlayer()
const episodeImage = ref(null)

onMounted(() => {
  useFetchCurrentProgramme()

  watch(
    () => currentEpisode.value,
    () => {
      episodeImage.value = useEpisodeImageFallback(currentEpisode.value?.image, currentEpisode.value?.show?.image)
    }
  )
})

const duration = computed(() => {
  const start = useFormatDate('utc', currentEpisode.value?.date.start)
  const end = useFormatDate('utc', currentEpisode.value?.date.end)
  return useFormatStartEndTime(start, end)
})
</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.player-header {
  display: flex;

  &__episode {
    display: flex;
    align-items: center;
    gap: var(--space--s);
    flex-grow: 1;
    padding: 4px 0 6px;
  }

  &__toggle {
    position: relative;
    @include tools.button-reset-styles;
  }

  &__cover {
    @include tools.square-image-crop;
  }

  &__image,
  &__toggle {
    position: relative;
    width: 50px;
    aspect-ratio: 1/1;
    @include tools.to(m) {
      width: 30px;
    }
  }

  &__info {
    display: table;
    width: 100%;
    text-decoration: none;
    table-layout: fixed;
  }

  &__title {
    @include tools.typo-header-player;
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__duration {
    @include tools.typo-small-player;
    display: table-row;
    word-spacing: -0.125em;
  }

  &__toggle-icon {
    @include tools.center;
    width: 15px;
    height: 15px;
  }
}
</style>
