<template>
  <div>
    <NuxtLoadingIndicator color="black" />
    <Header />
    <Main>
      <slot />
    </Main>
    <RenderCacheable :async-data-keys="['webform:newsletter_subscription']">
      <Footer />
    </RenderCacheable>
  </div>
</template>
