<template>
  <div id="footer" class="footer" role="contentinfo">
    <div class="footer__newsletter">
      <Newsletter />
    </div>
    <div class="footer__bottom">
      <div class="footer__content">
        <nav class="footer__nav">
          <ul class="footer__nav-list">
            <li v-for="(navItem, index) in appConfig.footerNav" :key="index" class="footer__nav-item">
              <NuxtLink :to="navItem.link" class="footer__nav-link">
                {{ navItem.label }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <div class="footer__text">
          97.5 MhZ │ <a href="https://www.digris.ch/verbreitung/programme/64b5715d-b44f-47f1-a678-c834996e86e2/" target="_blank">DAB+</a> │ <a href="https://stream.lora.ch/" target="_blank">stream.lora.ch</a>
        </div>
      </div>
      <div class="footer__contact">
        <ul class="footer__contact-list">
          <li v-for="(contactItem, index) in appConfig.contact" :key="index" class="footer__contact-item">
            <a :href="`${contactItem.type == 'Mail' ? 'mailto:' : contactItem.type == 'Phone' ? 'tel:' : '' }${contactItem.content}`" rel="nofollow" or target="_blank" class="footer__contact-item">
              <span class="footer__contact-item-text">
                {{ contactItem.type }}
              </span>
              <icon :name="`${contactItem.type}Outline`" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()

</script>

<style lang="scss">
@use "~/assets/sass/tools";

  .footer {
    @include tools.grid;

    &__newsletter {
      @include tools.page-grid-columns-wide;
      @include tools.boxshadow-layout(up);
    }

    &__bottom {
      @include tools.page-grid-columns-wide;
      @include tools.typo-small;
      @include tools.boxshadow-layout(up);
      display: flex;
      justify-content: space-between;
      gap: var(--grid-gutter);
      padding: var(--grid-gutter);
      background-color: var(--color-grey);
      @include tools.to(tools.$breakpoint--desktop) {
        flex-direction: column;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: calc(var(--grid-gutter) / 2);
    }

    &__nav-list {
      display: flex;
      gap: var(--grid-gutter--s);
      margin: 0;
      padding-left: 0;
      list-style: none;
      @include tools.to(tools.$breakpoint--desktop) {
        flex-direction: column;
      }
    }

    &__nav-item {
      margin-top: 0;
      padding-left: 0;
    }

    &__contact-list {
      display: flex;
      gap: var(--grid-gutter--xs);
      margin: 0;
      padding-left: 0;
      list-style: none;
    }

    &__contact-item {
      margin-top: 0;
      padding-left: 0;

      &:focus,
      &:focus-within {
        @include tools.focus-outline;
        border-radius: 50%;
      }
    }

    &__contact-item-text {
      @include tools.hide-visually;
    }
  }
</style>
