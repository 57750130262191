<template>
  <button
    class="menu-toggle-button"
    :class="{'menu-toggle-button--open': isOpen}"
    :aria-label="`Navigation ${openHeader ? `schliessen` : `öffnen`}`"
    :aria-expanded="openHeader"
    aria-controls="navigation"
    @click="toggleHeader"
  >
    <div class="menu-toggle-button__inner" />
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  open: boolean,
}>()

const openHeader = useState('openHeader', () => false)

function toggleHeader () {
  openHeader.value = !openHeader.value
}

const isOpen = computed(() => {
  return props.open
})
</script>

<style lang="scss">
@use "~/assets/sass/tools";

.menu-toggle-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(var(--icon-size--outline));
  height: calc(var(--icon-size--outline));
  padding: var(--space--s);
  border: var(--border);
  border-radius: 500px;
  box-shadow: none;

  &:hover,
  &:focus,
  &:focus-within {
    background-color: var(--color-background);
  }

  &__inner {
    position: relative;
    width: 100%;
    height: var(--border-width);
    background-color: var(--color-black);
    transition: all 0.2s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: var(--border-width);
      background-color: var(--color-black);
      transition: all 0.2s ease-in-out;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }

  &--open {
    .menu-toggle-button {
      &__inner {
        background-color: transparent;

        &::before { // stylelint-disable-line
          top: 0;
          transform: rotate(28.07deg);
          opacity: 1;
        }

        &::after { // stylelint-disable-line
          bottom: 0;
          transform: rotate(-28.07deg);
          opacity: 1;
        }
      }
    }
  }
}
</style>
