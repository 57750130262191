<template>
  <div class="newsletter">
    <div class="newsletter__inner">
      <h2 class="newsletter__heading">
        LoRa-Newsletter
      </h2>
      <p class="newsletter__text">
        News, Events, etc. von Radio LoRa
      </p>
      <Form form-id="newsletter_subscription" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.newsletter {
  @include tools.grid;
  max-width: var(--page-max-width);
  margin: auto;
  padding: calc(var(--space--s) * 1.5) var(--page-margin-horizontal) 0;

  &__inner {
    @include tools.page-grid-columns;
    @include tools.to(tools.$breakpoint--desktop) {
      padding: 0 var(--grid-gutter);
      padding-bottom: calc(var(--grid-gutter) * 1.5);
    }

    :deep(.form) {
      margin-top: var(--space--sm);
      padding: 0;
      border: 0;

      label {
        @include tools.typo-small;
      }

      button {
        @include tools.button-small;
      }

      .form-item {
        margin-top: var(--space--sm);

        &:first-child { // stylelint-disable-line
          margin-top: 0;
        }
      }

      .form-item-checkbox {
        label {
          margin-top: 0
        }
      }
    }
  }

  &__heading {
    @include tools.typo-heading-medium;
    margin: 0;
  }

  &__text {
    @include tools.typo-small;
    margin: 0;
    margin-top: var(--space--xs);
  }
}
</style>
