<template>
  <main id="main-content" class="main" role="main" tabindex="-1">
    <slot />
  </main>
</template>

<style lang="scss">
@use "~/assets/sass/tools";

  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: var(--page-max-width);
    margin: 0 auto;
    padding: calc(var(--page-margin-vertical) + var(--header-height)) var(--page-margin-horizontal) var(--page-margin-vertical--big);
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
  }
</style>
